import Decimal from 'decimal.js';
import React, { CSSProperties, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, Typography } from '@components/common';
import { TokenType } from '@interfaces/token';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';
import { calcDecimal, toLocaleStringNumber } from '@utils/number';
import DiscountCompletionLoader from './DiscountCompletionLoader';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.primary.light,
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.primary.main,
  },
}));

interface Props {
  isComplete?: boolean;
  totalPrice: number;
  targetPrice: number;
  discount: number;
  isLoading?: boolean;
  deliveryPrice?: number;
}

const ProductCartFormDiscountCompletion: React.FC<Props> = (props) => {
  const { t } = useTranslation();

  const { deliveryPrice = 0, totalPrice = 0, targetPrice = 0, discount = 0, isLoading } = props;

  const fallbackDecimal = calcDecimal(0) as Decimal;
  const parsedTotalPrice = calcDecimal(totalPrice);
  const parsedTargetPrice = calcDecimal(targetPrice);

  const decimalTotalPrice = parsedTotalPrice instanceof Decimal ? parsedTotalPrice : fallbackDecimal;
  const decimalTargetPrice = parsedTargetPrice instanceof Decimal ? parsedTargetPrice : fallbackDecimal;

  const isComplete = totalPrice >= targetPrice;
  const progressValue =
    totalPrice >= targetPrice ? 100 : decimalTotalPrice.div(decimalTargetPrice).mul(100)?.toNumber();

  const targetPriceLeft = toLocaleStringNumber(decimalTargetPrice.minus(decimalTotalPrice)?.toString());
  const discountString = toLocaleStringNumber(calcDecimal(discount)?.toString());
  const deliveryPriceString = toLocaleStringNumber(calcDecimal(deliveryPrice)?.toString());

  const completedContainerProps = useMemo(() => {
    if (isComplete) {
      return {
        padding: 'xs' as TokenType['spacing'],
        borderRadius: 'default' as TokenType['borderRadius'],
        borderColor: 'uiPrimaryMain' as TokenType['color'],
        borderWidth: 'bold' as TokenType['borderWidth'],
        position: 'relative' as CSSProperties['position'],
        paddingBottom: 'xl' as TokenType['spacing'],
      };
    }

    return null;
  }, [isComplete]);

  if (isLoading) {
    return <DiscountCompletionLoader />;
  }

  // TODO: Integrate this
  const promoName = 'Promo Lebaran';

  return (
    <Stack spacing={'xs'} {...completedContainerProps}>
      <Stack direction={'row'} justifyContent={'space-between'}>
        <Typography size={'hm'}>
          {t('products.delivery_price_label', { promoName, deliveryPrice: deliveryPriceString })}
        </Typography>
        <Stack direction={'row'} spacing={'xxs'}>
          <Typography size={'hs'} color={'uiPrimaryMain'}>
            {toLocaleStringNumber(String(totalPrice))}
          </Typography>
          <Typography size={'hs'} color={'uiDarkSecondary'}>{`(${toLocaleStringNumber(
            String(targetPrice)
          )})`}</Typography>
        </Stack>
      </Stack>
      <BorderLinearProgress variant={'determinate'} value={progressValue} />
      {!isComplete && (
        <Typography size={'hs'}>
          {t('products.cart_discount_description', { priceLeft: targetPriceLeft, discount: discountString })}
        </Typography>
      )}

      {isComplete && (
        <Stack
          alignItems={'center'}
          width={'100%'}
          justifyContent={'center'}
          position={'absolute'}
          left={0}
          bottom={-15}
        >
          <Stack
            background={'uiPrimaryMain'}
            borderRadius={'default'}
            padding={'xxs'}
            paddingX={'s'}
            width={'fit-content'}
            justifyContent={'center'}
            alignItems={'center'}
          >
            <Typography size={'s'} variant={'bold'} color={'uiLightPrimary'}>
              {t('products.discount_completed_success_label')}
            </Typography>
          </Stack>
        </Stack>
      )}
    </Stack>
  );
};

export default ProductCartFormDiscountCompletion;
