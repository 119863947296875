import { useRouter } from 'next/router';
import { useEffect, useMemo } from 'react';
import { Stack } from '@components/common';
import {
  ProductCartForm,
  ProductCartFormSuggestedProductList,
  ProductCartSubmitButton,
} from '@components/productCarts';
import routes from '@constants/routes';
import { usePersistentQueryParams } from '@hooks/common';
import { useDineInOrderDetail, useDineInRedirectByStatus } from '@hooks/dineIn';
import { useRedirectByLocationSchedule } from '@hooks/locations';
import { useRedirectEmptyProductCart } from '@hooks/products';
import { useCommonStore } from '@hooks/storage';
import { useQueryProductCartList } from '@queries/products';

const ProductCartContainer = () => {
  useDineInRedirectByStatus();
  useRedirectEmptyProductCart();

  const router = useRouter();
  const { query } = usePersistentQueryParams();

  const { storageState, updateStorage } = useCommonStore();
  const activeLocationId = storageState.activeLocation?.id;

  const { isFetching, productCartList, refetchProductList } = useQueryProductCartList();

  const { isDineIn, dineInDetail, isFetching: isFetchingDineInDetail } = useDineInOrderDetail();

  const { isOpen, isFetchingLocationDetail } = useRedirectByLocationSchedule({
    locationId: isDineIn ? dineInDetail?.locationId : activeLocationId,
    onSuccess: (location) => {
      updateStorage({
        activeLocation: location,
      });
    },
  });

  const isInvalidToOrder = useMemo(
    () => !isOpen && !isFetchingDineInDetail && !dineInDetail?.tableNo && !isFetchingLocationDetail,
    [dineInDetail?.tableNo, isFetchingDineInDetail, isFetchingLocationDetail, isOpen]
  );

  useEffect(() => {
    if (isInvalidToOrder) {
      router.push({
        pathname: routes.ROOT,
        query,
      });
    }
  }, [isInvalidToOrder, query, router]);

  return (
    <Stack direction={'column'} padding={'m'} spacing={'xxl'} flexGrow={1} justifyContent={'space-between'}>
      <Stack direction={'column'} spacing={'xxl'}>
        <ProductCartForm />
        {/* TODO: Integrate this when its ready */}
        {/* <Stack>
          <ProductCartFormDiscountCompletion
            isLoading={isLoading}
            discount={10000}
            targetPrice={300000}
            totalPrice={totalPrice}
            deliveryPrice={10000}
          />
        </Stack> */}
        <ProductCartFormSuggestedProductList />
      </Stack>
      <ProductCartSubmitButton
        productList={productCartList}
        disabled={isFetching || isInvalidToOrder}
        refetchProductList={refetchProductList}
      />
    </Stack>
  );
};

export default ProductCartContainer;
