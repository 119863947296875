import Fuse from 'fuse.js';
import { useRouter } from 'next/router';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Product } from '@api/products/types';
import { CustomTextField, Stack, Typography } from '@components/common';
import { ProductCard, ProductListSubmitButton } from '@components/products';
import { ProductListLoader } from '@components/products/loaders';
import { NAVBAR_HEIGHT } from '@constants/common';
import routes from '@constants/routes';
import Token from '@constants/token';
import { usePersistentQueryParams } from '@hooks/common';
import { useDineInOrderDetail } from '@hooks/dineIn';
import { useRedirectByLocationSchedule } from '@hooks/locations';
import { useCartStore, useCommonStore } from '@hooks/storage';
import { TextFieldProps, debounce } from '@mui/material';
import { useQueryProductCartList, useQueryProducts } from '@queries/products';
import { CartItem } from '@storage/types';
import { toLocaleStringNumber } from '@utils/number';
import { getTotalProduct } from '@utils/storage/cartStore';

const productSearchKeys = [
  {
    name: 'name',
    weight: 16,
  },
  {
    name: 'category',
    weight: 8,
  },
  {
    name: 'description',
    weight: 4,
  },
  {
    name: 'productOptionSets.optionSetName',
    weight: 2,
  },
  {
    name: 'productOptionSets.optionSetOptions.productName',
    weight: 1,
  },
];

const ProductSearchContainer = () => {
  const { t } = useTranslation();
  const router = useRouter();
  const { query } = usePersistentQueryParams();

  const [keyword, setKeyword] = useState('');

  const { isFetching, data, queryKeys } = useQueryProducts();
  const { isFetching: isFetchingCartList, prices, productCartList } = useQueryProductCartList();
  const { isDineIn, dineInDetail } = useDineInOrderDetail();

  const { storageState, isFinishInitiated: isFinishLoadCommonStore } = useCommonStore();
  const activeLocationId =
    isDineIn && dineInDetail?.locationId ? dineInDetail?.locationId : storageState.activeLocation?.id;

  const {
    updateProductCartItem,
    branchCart,
    isFinishInitiated: isFinishLoadCartStore,
  } = useCartStore(activeLocationId);

  const { isOpen } = useRedirectByLocationSchedule({ locationId: activeLocationId, skipRedirect: true });

  const handeOnChangeKeyword: TextFieldProps['onChange'] = debounce((e) => {
    setKeyword(e.target.value);
  }, 500);

  const onSetItem = (productId: number) => (item: CartItem) => {
    updateProductCartItem(productId, { ...item, queryKeys });
  };

  const productList = useMemo(() => {
    const productCategories = data?.productCategories || [];

    const productList = productCategories.reduce((acc: (Product & { category: string })[], currentCategory) => {
      const currentProducts = currentCategory?.products || [];
      const productCategoryName = currentCategory.productCategoryName;
      return [...acc, ...currentProducts.map((product) => ({ ...product, category: productCategoryName }))];
    }, []);

    return productList;
  }, [data?.productCategories]);

  const productFuzzy = new Fuse(productList, {
    keys: productSearchKeys,
    threshold: 0.5,
    includeMatches: true,
    includeScore: true,
  });

  const searchResult = productFuzzy.search(keyword);

  const mappedSearchResult = useMemo(() => {
    const productMap: { [key: string]: Product[] } = {};

    searchResult.forEach((value) => {
      const category = value.item.category;
      const productList = productMap[category] || [];
      productMap[category] = [...productList, value.item];
    });

    return productMap;
  }, [searchResult]);

  const totalProduct = useMemo(() => getTotalProduct(branchCart), [branchCart]);

  const onPressSubmit = () => {
    router.replace({
      pathname: routes.PRODUCT_CART,
      query,
    });
  };

  const disabledSubmitButton = useMemo(() => {
    if (!isOpen) {
      return true;
    }
    const productIds = Object.keys(branchCart);

    return productIds.some((productId) => {
      const matchedProduct = productCartList.find(({ id }) => productId === String(id));
      if (!matchedProduct || matchedProduct?.outOfStockFlag) {
        return true;
      }

      const selectedProduct = branchCart[Number(productId)];
      return selectedProduct?.customItems?.some((customItem) =>
        customItem.productOptionSets?.some((optionSet) => {
          const matchedOptionSet = matchedProduct?.productOptionSets?.find?.(
            ({ optionSetId }) => optionSetId === optionSet?.optionSetId
          );
          return optionSet?.optionSetOptionIds?.some((optionSetOptionId) =>
            matchedOptionSet?.optionSetOptions?.some(
              ({ id, outOfStockFlag }) => optionSetOptionId === id && outOfStockFlag
            )
          );
        })
      );
    });
  }, [branchCart, isOpen, productCartList]);

  const renderList = () => {
    if (isFetching || !isFinishLoadCommonStore || !isFinishLoadCartStore) {
      return <ProductListLoader />;
    }

    const productCategories = Object.keys(mappedSearchResult);

    return productCategories.map((category) => {
      const products = mappedSearchResult[category];
      return (
        <Stack spacing={'m'} key={category}>
          <Typography size={'xl'} variant={'bold'}>
            {category}
          </Typography>
          {products.map((product, index) => (
            <ProductCard
              cartItem={branchCart[product.id]}
              key={index}
              onChangeCartItem={onSetItem(product?.id)}
              product={product}
              productCategoryId={product?.productCategoryId}
              productCategoryName={product?.productCategoryName}
            />
          ))}
        </Stack>
      );
    });
  };

  return (
    <Stack paddingX={'m'} flexGrow={1} justifyContent={'space-between'}>
      <Stack direction={'column'}>
        <Stack
          background={'uiLightPrimary'}
          spacing={'xs'}
          paddingY={'s'}
          position={'sticky'}
          top={NAVBAR_HEIGHT}
          zIndex={1}
        >
          <Typography size={'hs'} variant={'bold'}>
            {t('products.title')}
          </Typography>
          <CustomTextField placeholder={t('products.search_placeholder')} autoFocus onChange={handeOnChangeKeyword} />
        </Stack>
        <Stack spacing={'m'} paddingX={'xxs'} marginY={'m'} borderRadius={'default'}>
          {renderList()}
        </Stack>
      </Stack>

      <Stack
        position={'sticky'}
        bottom={Token.spacing.hm}
        maxWidth={'max-content'}
        alignSelf={'center'}
        alignItems={'center'}
        zIndex={1}
      >
        <ProductListSubmitButton
          disabled={disabledSubmitButton}
          onPress={onPressSubmit}
          totalProduct={totalProduct}
          totalPrice={toLocaleStringNumber(String(prices.totalPrice))}
          isLoading={isFetchingCartList}
        />
      </Stack>
    </Stack>
  );
};

export default ProductSearchContainer;
