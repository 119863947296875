import { useRouter } from 'next/router';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { SnackbarContext } from 'src/context/SnackbarProvider';
import { APIDefaultErrors } from '@api/baseAPI/types';
import { createDineInCustomerOrder } from '@api/dineIn';
import { GetDineInCustomerOrderPriceRequest } from '@api/dineIn/types';
import { Product } from '@api/products/types';
import { Dialog, Hoverable, Stack, Transition, Typography } from '@components/common';
import { CheckmarkOutlinedLiveIcon } from '@components/icons';
import { UpdateProfileNameFormDialog } from '@components/order';
import routes from '@constants/routes';
import { usePersistentQueryParams } from '@hooks/common';
import { useCheckDineIn } from '@hooks/dineIn';
import { useCartStore, useCommonStore, useGuestStore } from '@hooks/storage';
import { useQueryProfileDetail } from '@queries/auth';
import { useQueryDineInCustomerOrderPrice } from '@queries/dineIn';
import { getUserCookies } from '@utils/auth';
import { getErrorMessage } from '@utils/common';
import { addLoadingText } from '@utils/emotion';
import { getOrderRequestProducts } from '@utils/product';

interface Props {
  isOpen: boolean;
  closeModal: () => void;
  openModal: () => void;
  productList: Product[];
}

const DEFAULT_TIMER_IN_SECONDS = 5000;

const SubmitOpenBillOrderDialog = ({ isOpen, closeModal, productList, openModal }: Props) => {
  const { t } = useTranslation();
  const snackbarContext = useContext(SnackbarContext);
  const queryClient = useQueryClient();
  const [openProfileNameFormDialog, setOpenProfileNameFormDialog] = useState(false);

  const router = useRouter();
  const { query } = usePersistentQueryParams();
  const [timeLeft, setTimeLeft] = useState(() => DEFAULT_TIMER_IN_SECONDS);

  const timeLeftInSeconds = timeLeft / 1000;
  const timeLeftForTimer = Number.isNaN(timeLeftInSeconds) ? 0 : timeLeftInSeconds;

  const { openBillToken = '' } = useCheckDineIn();

  const { storageState, isFinishInitiated: isFinishLoadCommonStore, updateStorage } = useCommonStore();
  const webPushToken = storageState?.firebaseToken;

  const { guestData, refetch: refetchGuestStore } = useGuestStore();
  const { isLoggedIn } = getUserCookies();

  const activeLocationId = storageState.activeLocation?.id;
  const activeUuid = storageState.uuid;

  const { isFinishInitiated: isFinishLoadCartStore, branchCart, clearCart } = useCartStore(activeLocationId);
  const products = useMemo(() => getOrderRequestProducts(branchCart, productList), [branchCart, productList]);

  const handleClearCart = useCallback(() => {
    clearCart();
    updateStorage({ uuid: undefined });
  }, [clearCart, updateStorage]);

  const { data: profileData } = useQueryProfileDetail({
    enabled: isLoggedIn && isOpen,
  });

  const onOpenProfileNameFormDialog = () => setOpenProfileNameFormDialog(true);
  const onCloseProfileNameFormDialog = () => setOpenProfileNameFormDialog(false);

  const getCustomerOrderPriceParams = useMemo((): Partial<GetDineInCustomerOrderPriceRequest> => {
    return {
      locationId: activeLocationId,
      products,
      paymentMethod: 'open_bill',
      paymentMethodType: null,
      openBillDetail: {
        uuid: openBillToken,
      },
    };
  }, [activeLocationId, openBillToken, products]);

  const enableGetCustomerOrderPrice = isOpen && isFinishLoadCartStore && products.length > 0 && !!activeLocationId;

  const {
    isFetching: isFetchingOrderPrice,
    data: customerOrderPriceData,
    error: customerOrderPriceError,
    queryKeys: customerOrderPriceQueryKeys,
  } = useQueryDineInCustomerOrderPrice(getCustomerOrderPriceParams, {
    onSuccess: ({ applicablePromoIds }) => {
      updateStorage({ applicablePromoIds });
    },
    onError: (e) => {
      const { message = t('error.general_error_label') } = e as Error;
      snackbarContext?.openSnackbar?.({
        message,
        alertProps: {
          severity: 'error',
        },
      });
    },
    enabled: enableGetCustomerOrderPrice,
  });

  const customerOrderPriceErrorMessage = getErrorMessage(
    customerOrderPriceError as APIDefaultErrors,
    t('order.fetch_order_price_error')
  );

  const {
    mutate: onCreateCustomerOrder,
    isLoading: isCreatingCustomerOrder,
    data: createCustomerOrderData,
    error: createCustomerOrderError,
    reset: resetCreateCustomerOrder,
  } = useMutation(createDineInCustomerOrder, {
    onSuccess: () => {
      handleClearCart();
    },
    onError: (e) => {
      const { message = t('error.general_error_label') } = e as Error;
      snackbarContext?.openSnackbar?.({
        message,
        alertProps: {
          severity: 'error',
        },
      });
      closeModal();
    },
  });
  const createCustomerOrderErrorMessage = getErrorMessage(
    createCustomerOrderError as APIDefaultErrors,
    t('dine_in.create_customer_order_open_bill_error')
  );

  const errorMessage = customerOrderPriceErrorMessage || createCustomerOrderErrorMessage;
  const isTimerLoading = timeLeftForTimer > 0 && !errorMessage;

  useEffect(() => {
    if (isOpen || !isOpen) {
      setTimeLeft(DEFAULT_TIMER_IN_SECONDS);
    }
  }, [isOpen]);

  useEffect(() => {
    if (isOpen) {
      queryClient.resetQueries(customerOrderPriceQueryKeys);
      resetCreateCustomerOrder();
      refetchGuestStore();
    }
  }, [customerOrderPriceQueryKeys, isOpen, queryClient, refetchGuestStore, resetCreateCustomerOrder]);

  const handleOpenDialogName = useCallback(() => {
    onOpenProfileNameFormDialog();
    closeModal();
  }, [closeModal]);

  useEffect(() => {
    if (storageState.activeLocation?.guestModeDefault) {
      return;
    }
    if ((isLoggedIn && !!profileData?.name) || !!guestData) {
      return;
    }

    if (isOpen && timeLeft === 0) {
      handleOpenDialogName();
    }
  }, [
    guestData,
    handleOpenDialogName,
    isLoggedIn,
    isOpen,
    profileData?.name,
    timeLeft,
    storageState.activeLocation?.guestModeDefault,
  ]);

  useEffect(() => {
    if (isOpen && timeLeft === 0 && customerOrderPriceData && !errorMessage && activeLocationId && !!activeUuid) {
      if (!!profileData?.name || !!guestData || storageState.activeLocation?.guestModeDefault) {
        onCreateCustomerOrder({
          webPushToken,
          locationId: activeLocationId,
          products,
          priceDetail: customerOrderPriceData,
          paymentMethod: 'open_bill',
          openBillDetail: {
            uuid: openBillToken,
          },
          guestContactNumber: guestData?.contactNumber,
          guestFullname: guestData?.name,
          guestCountryCode: guestData?.countryCode,
          uuid: activeUuid,
        });
      }
    }
  }, [
    isOpen,
    timeLeft,
    customerOrderPriceData,
    errorMessage,
    activeLocationId,
    activeUuid,
    guestData,
    storageState.activeLocation?.guestModeDefault,
    profileData?.name,
    onCreateCustomerOrder,
    openBillToken,
    webPushToken,
    products,
  ]);

  const handleSuccessUpdateProfile = () => {
    onCloseProfileNameFormDialog();
    openModal();
  };

  useEffect(() => {
    const updateTimer = () => {
      if (isOpen) {
        setTimeLeft((prevTimeLeft) => prevTimeLeft - 1000);
      }
    };

    const timer = setInterval(() => {
      if (timeLeft <= 0) {
        clearInterval(timer);
        return;
      }

      updateTimer();
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, [isOpen, timeLeft]);

  const isLoadingData = isFetchingOrderPrice || isCreatingCustomerOrder || !isFinishLoadCommonStore;

  const generateMessage = useCallback(() => {
    if (isLoadingData || isTimerLoading) {
      return (
        <Stack alignContent={'center'} textAlign={'center'} rowGap={'xs'}>
          <Typography variant={'bold'} size={'m'} css={addLoadingText('uiDarkPrimary')}>
            {t('dine_in.submit_order_title')}
          </Typography>
          <Typography size={'xs'} color={'uiLightStainSecondary'}>
            {t('dine_in.submitted_order_description')}
          </Typography>
        </Stack>
      );
    }

    if (errorMessage) {
      return (
        <>
          <Typography variant={'bold'} size={'m'}>
            {t('dine_in.submit_order_error_title')}
          </Typography>

          <Typography variant={'medium'} color={'uiRedPrimary'} size={'s'} align={'center'}>
            {errorMessage}
          </Typography>
        </>
      );
    }

    return (
      <>
        <CheckmarkOutlinedLiveIcon width={56} height={56} color={'uiPrimaryMain'} />
        <Typography variant={'bold'} size={'m'}>
          {t('dine_in.submitted_order_title')}
        </Typography>
      </>
    );
  }, [errorMessage, isLoadingData, isTimerLoading, t]);

  const handleCloseModal = useCallback(() => {
    if (isCreatingCustomerOrder) {
      return;
    }

    if (createCustomerOrderData?.id) {
      handleClearCart();
      router.push({
        pathname: routes.PRODUCT_LIST,
        query,
      });
      return;
    }

    closeModal();
  }, [handleClearCart, closeModal, createCustomerOrderData?.id, isCreatingCustomerOrder, query, router]);

  return (
    <>
      <Dialog open={isOpen} TransitionComponent={Transition} borderRadius={'large'}>
        <Stack
          spacing={'xxl'}
          justifyContent={'center'}
          alignItems={'center'}
          paddingLeft={'xxl'}
          paddingRight={'xxl'}
          paddingTop={'xxxl'}
          paddingBottom={'xxxl'}
          width={'300px'}
        >
          {generateMessage()}
          <Hoverable onClick={handleCloseModal}>
            <Typography
              variant={'medium'}
              color={isTimerLoading ? 'uiPrimaryMain' : 'uiLightStainSecondary'}
              size={'s'}
              opacity={isTimerLoading ? 1 : 0.4}
            >
              {isTimerLoading
                ? t('dine_in.submit_order_cancel_timer', { timeLeft: timeLeftForTimer })
                : t('actions.close')}
            </Typography>
          </Hoverable>
        </Stack>
      </Dialog>
      <UpdateProfileNameFormDialog
        open={openProfileNameFormDialog}
        onClose={onCloseProfileNameFormDialog}
        onSuccessSubmit={handleSuccessUpdateProfile}
      />
    </>
  );
};

export default SubmitOpenBillOrderDialog;
