import { useRouter } from 'next/router';
import React, { MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { Product } from '@api/products/types';
import { Hoverable, Image, Stack, Typography } from '@components/common';
import { PlusCircleIcon } from '@components/icons';
import { OutOfStockTag } from '@components/products';
import routes from '@constants/routes';
import Token from '@constants/token';
import { useProductPrice } from '@hooks/products';
import { useCommonStore } from '@hooks/storage';
import { useQueryBrandInfo } from '@queries/commons';
import { CartItem } from '@storage/types';

interface Props {
  product: Product;
  cartItem?: CartItem;
  disabled?: boolean;
  onChangeCartItem?: (item: CartItem) => void;
}
const ProductCartFormSuggestedCard: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const { data } = useQueryBrandInfo();

  const { product, cartItem, onChangeCartItem } = props;
  const router = useRouter();
  const { storageState } = useCommonStore();

  const { name, imageUrl, price } = product;
  const { label: priceLabel } = useProductPrice(price);

  const outOfStock = !product || product.outOfStockFlag;
  const disabled = props.disabled || outOfStock;

  const hasProductOptionSet = product?.type === 'option_set';
  const isCustomProduct = hasProductOptionSet;

  const onOpenProductDetailPage = () => {
    if (disabled) {
      return;
    }

    router.push({
      pathname: `${routes.PRODUCT_LIST}/${product.id}`,
      query: {
        ...router.query,
        locationId: storageState.activeLocation?.id,
        subBrandId: storageState.subBrand?.id,
      },
    });
  };

  const onPressCard = (event: MouseEvent<HTMLAnchorElement>) => {
    event.stopPropagation();
    event.preventDefault();

    if (disabled) {
      return;
    }

    if (isCustomProduct) {
      onOpenProductDetailPage();
    } else {
      onChangeCartItem?.({
        ...cartItem,
        id: product?.id,
        qty: 1,
        productCategoryId: product?.productCategoryId,
        productCategoryName: product?.productCategoryName,
      });
    }
  };

  return (
    <React.Fragment>
      <Stack
        onClick={onOpenProductDetailPage}
        padding={'xs'}
        borderColor={'borderSubtle'}
        borderWidth={'bolder'}
        borderRadius={'default'}
        position={'relative'}
        width={88}
      >
        {outOfStock ? (
          <Stack position={'absolute'} right={'0'} top={Token.spacing.s}>
            <OutOfStockTag />
          </Stack>
        ) : (
          <Stack position={'absolute'} right={Token.spacing.xxxs} top={Token.spacing.xxxs}>
            <Hoverable onClick={onPressCard} disabled={disabled} display={'inline-flex'}>
              <PlusCircleIcon width={24} height={24} />
            </Hoverable>
          </Stack>
        )}

        <Image
          alt={t('general.alt_product_image', { productName: name, brandName: data?.brand?.name })}
          width={88}
          height={88}
          src={imageUrl}
        />
        <Stack marginTop={'xs'} alignContent={'center'} textAlign={'center'}>
          <Typography size={'s'} color={outOfStock ? 'uiLightTertiary' : 'uiDarkPrimary'}>
            {priceLabel}
          </Typography>
          <Typography size={'xs'} color={'uiDarkSecondary'} lineClamp={2}>
            {name}
          </Typography>
        </Stack>
      </Stack>
    </React.Fragment>
  );
};

export default ProductCartFormSuggestedCard;
