import { useRouter } from 'next/router';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AuthDialogContext } from 'src/context/AuthDialogProvider';
import { SnackbarContext } from 'src/context/SnackbarProvider';
import { v4 as uuidv4 } from 'uuid';
import { Product } from '@api/products/types';
import { Button, Stack, Typography } from '@components/common';
import routes from '@constants/routes';
import { usePersistentQueryParams } from '@hooks/common';
import { useCheckDineIn } from '@hooks/dineIn';
import { useCartStore, useCommonStore, useGuestStore } from '@hooks/storage';
import { getUserCookies } from '@utils/auth';
import CheckCartDialog from './CheckCartDialog';
import ProductCartOnlineSubmitButton from './ProductCartOnlineSubmitButton';
import SubmitOpenBillOrderDialog from './SubmitOpenBillOrderDialog';

interface Props {
  productList: Product[];
  disabled?: boolean;
  refetchProductList?: () => void;
}

const ProductCartSubmitButton = ({ productList = [], disabled = false, refetchProductList }: Props) => {
  const { t } = useTranslation();
  const router = useRouter();
  const { query } = usePersistentQueryParams();
  const { isLoggedIn } = getUserCookies();
  const authFormContext = useContext(AuthDialogContext);
  const [isOpenDineInDialog, setIsOpenDineInDialog] = useState(false);
  const handleOpenDineInDialog = () => setIsOpenDineInDialog(true);

  const [isOpenCheckDialog, setIsOpenCheckDialog] = useState(false);

  const snackbarContext = useContext(SnackbarContext);

  const { guestData, isLoading: isLoadingGuestData, setGuestInfo } = useGuestStore();
  const { isDineIn, isOpenBill, isClosedBill } = useCheckDineIn();
  const { storageState, updateStorage, isFetching: isFetchingStorage } = useCommonStore();

  const { branchCart, updateProductCartItem } = useCartStore(storageState.activeLocation?.id);

  useEffect(() => {
    if (!disabled) {
      const branchCartProductIds = Object.keys(branchCart) || [];
      const isHaveInvalidProduct = branchCartProductIds.some((productId) => {
        const matchedProduct = productList.find(({ id }) => Number(productId) === id);
        if (!matchedProduct || matchedProduct?.outOfStockFlag) {
          updateProductCartItem(Number(productId), {});
          return true;
        }

        return false;
      });

      if (isHaveInvalidProduct) {
        snackbarContext?.openSnackbar?.({
          message: t('products.one_of_products_out_of_stock'),
          alertProps: {
            severity: 'error',
          },
        });
      }
    }
  }, [branchCart, disabled, productList, snackbarContext, t, updateProductCartItem]);

  const handleCloseDineInDialog = () => {
    setIsOpenDineInDialog(false);
  };

  const handleRedirectAfterSubmit = useCallback(() => {
    setIsOpenCheckDialog(true);
    if (isOpenBill) {
      setTimeout(() => {
        setIsOpenCheckDialog(false);
        setIsOpenDineInDialog(true);
      }, 3000);
      return;
    }

    if (isClosedBill) {
      router.push({
        pathname: routes.CLOSED_BILL_DETAIL,
        query,
      });
      return;
    }

    router.push({
      pathname: routes.ORDER_PLACEMENT,
      query,
    });
  }, [isClosedBill, isOpenBill, query, router]);

  const handleSubmit = useCallback(() => {
    const checkCurrentUuid = storageState.uuid;
    if (!checkCurrentUuid) {
      updateStorage({ uuid: uuidv4() });
    }
    if (isLoggedIn) {
      handleRedirectAfterSubmit();
      return;
    }

    if (guestData && !isLoadingGuestData) {
      handleRedirectAfterSubmit();
      return;
    }

    if (
      !guestData &&
      storageState.activeLocation?.guestModeDefault &&
      !storageState.activeLocation?.requireGuestName &&
      !storageState.activeLocation?.requireGuestPhoneNumber &&
      isDineIn
    ) {
      setGuestInfo({
        name: t('sessions.guest'),
        contactNumber: '',
        countryCode: '',
      });
      handleRedirectAfterSubmit();
      return;
    }

    authFormContext?.openDialog?.(() => handleRedirectAfterSubmit);
  }, [
    storageState.uuid,
    storageState.activeLocation?.guestModeDefault,
    storageState.activeLocation?.requireGuestName,
    storageState.activeLocation?.requireGuestPhoneNumber,
    isLoggedIn,
    guestData,
    isLoadingGuestData,
    isDineIn,
    authFormContext,
    updateStorage,
    handleRedirectAfterSubmit,
    setGuestInfo,
    t,
  ]);

  const onSubmit = useCallback(async () => {
    setIsOpenCheckDialog(true);
    await refetchProductList?.();

    setTimeout(() => {
      setIsOpenCheckDialog(false);
      return handleSubmit();
    }, 3000);
  }, [handleSubmit, refetchProductList]);

  if (isDineIn) {
    return (
      <Stack flexDirection={'column'} spacing={'s'} justifyContent={'center'}>
        <Typography align={'center'} size={'hxxs'} variant={'medium'} color={'uiLightTertiary'}>
          {t('products.submit_dine_in_description')}
        </Typography>
        <Button disabled={disabled || isFetchingStorage} onClick={onSubmit} variant={'contained'} fullWidth>
          <Typography variant={'bold'}>{t('products.submit_dine_in')}</Typography>
        </Button>

        {isOpenBill && (
          <SubmitOpenBillOrderDialog
            isOpen={isOpenDineInDialog}
            closeModal={handleCloseDineInDialog}
            openModal={handleOpenDineInDialog}
            productList={productList}
          />
        )}

        <CheckCartDialog isOpen={isOpenCheckDialog} />
      </Stack>
    );
  }

  return (
    <>
      <ProductCartOnlineSubmitButton
        productList={productList}
        disabled={disabled || isFetchingStorage}
        handleSubmit={onSubmit}
      />
      <CheckCartDialog isOpen={isOpenCheckDialog} />
    </>
  );
};

export default ProductCartSubmitButton;
