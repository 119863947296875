import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Product } from '@api/products/types';
import { Stack, Typography } from '@components/common';
import { useCommonStore } from '@hooks/storage';
import useCartStore from '@hooks/storage/useCartStore';
import { useQueryProducts } from '@queries/products';
import { CartItem } from '@storage/types';
import ProductCartFormSuggestedCard from './ProductCartFormSuggestedCard';
import SuggestedProductListLoader from './SuggestedProductListLoader';

const ProductCartFormSuggestedProductList = () => {
  const { t } = useTranslation();

  const { storageState, isFinishInitiated: isFinishLoadCommonStore } = useCommonStore();
  const activeLocationId = storageState.activeLocation?.id;
  const {
    updateProductCartItem,
    branchCart,
    isFinishInitiated: isFinishLoadCartStore,
  } = useCartStore(activeLocationId);

  const { data, isFetching, queryKeys } = useQueryProducts();

  const productCategories = useMemo(() => data?.productCategories || [], [data?.productCategories]);
  const suggestedProductIds = useMemo(
    () => data?.popularMenu?.productCheckoutIds || [],
    [data?.popularMenu?.productCheckoutIds]
  );

  const productList = useMemo(() => {
    return productCategories.reduce((acc: Product[], productCategory) => {
      const currProducts = productCategory?.products || [];
      return [...acc, ...currProducts];
    }, []);
  }, [productCategories]);

  const suggestedProductList = useMemo(
    () =>
      productList.filter(
        ({ id, outOfStockFlag }) => suggestedProductIds.includes(id) && !branchCart[id] && !outOfStockFlag
      ),
    [branchCart, productList, suggestedProductIds]
  );

  const onSetItem = (productId: number) => (item: CartItem) => {
    updateProductCartItem(productId, { ...item, queryKeys });
  };

  if (isFetching || !isFinishLoadCommonStore || !isFinishLoadCartStore) {
    return <SuggestedProductListLoader />;
  }

  if (!suggestedProductList.length) {
    return null;
  }

  return (
    <Stack direction={'column'} spacing={'xs'}>
      <Stack padding={'xs'} background={'uiGreyLight'} borderRadius={'form'}>
        <Typography variant={'medium'} size={'hs'} color={'contentTertiary'}>
          {t('products.add_to_basket_label')}
        </Typography>
      </Stack>
      <Stack spacing={'s'} overflowX={'auto'} direction={'row'}>
        {suggestedProductList.map((product, index) => (
          <ProductCartFormSuggestedCard
            product={product}
            key={index}
            cartItem={branchCart[product?.id]}
            onChangeCartItem={onSetItem(product?.id)}
          />
        ))}
      </Stack>
    </Stack>
  );
};

export default ProductCartFormSuggestedProductList;
