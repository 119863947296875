import { useTranslation } from 'react-i18next';
import { Dialog, Stack, Transition, Typography } from '@components/common';
import { addLoadingText } from '@utils/emotion';

interface Props {
  isOpen: boolean;
}

const CheckCartDialog = ({ isOpen }: Props) => {
  const { t } = useTranslation();

  return (
    <Dialog open={isOpen} TransitionComponent={Transition} borderRadius={'large'}>
      <Stack
        spacing={'xxl'}
        justifyContent={'center'}
        alignItems={'center'}
        paddingLeft={'xxl'}
        paddingRight={'xxl'}
        paddingTop={'xxxl'}
        paddingBottom={'xxxl'}
        width={'300px'}
      >
        <Stack alignContent={'center'} textAlign={'center'} rowGap={'xs'}>
          <Typography variant={'bold'} size={'m'} css={addLoadingText('uiDarkPrimary')}>
            {t('general.load_more')}
          </Typography>
          <Typography size={'xs'} color={'uiLightStainSecondary'}>
            {t('general.checking_availability')}
          </Typography>
        </Stack>
      </Stack>
    </Dialog>
  );
};

export default CheckCartDialog;
