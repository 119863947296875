import React from 'react';
import { Stack, Typography } from '@components/common';
import { Skeleton } from '@mui/material';

const ProductCartFormLoader: React.FC = () => {
  return (
    <Stack direction={'column'} spacing={'xl'}>
      <Typography size={'xl'} variant={'bold'}>
        <Skeleton variant={'text'} width={150} />
      </Typography>
      {new Array(5).fill(null).map((_, idx) => (
        <Stack key={idx} direction={'column'} spacing={'xs'}>
          <Stack direction={'row'} spacing={'m'}>
            <Skeleton variant={'rectangular'} width={78} height={78} />
            <Stack direction={'column'} justifyContent={'space-between'} spacing={'xs'}>
              <Stack direction={'column'} spacing={'xs'} paddingTop={'xs'}>
                <Stack direction={'row'} alignItems={'center'} spacing={'xs'}>
                  <Typography variant={'bold'}>
                    <Skeleton variant={'text'} width={100} />
                  </Typography>
                  <Skeleton variant={'rectangular'} width={28} height={28} />
                </Stack>
                <Typography variant={'bold'}>
                  <Skeleton variant={'rectangular'} width={60} />
                </Typography>
                <Typography size={'hs'} color={'uiPrimaryMain'}>
                  <Skeleton variant={'text'} width={40} />
                </Typography>
              </Stack>
              <Skeleton variant={'rectangular'} width={100} height={30} />
            </Stack>
          </Stack>
        </Stack>
      ))}
    </Stack>
  );
};

export default ProductCartFormLoader;
