import { useAtom } from 'jotai';
import { useRouter } from 'next/router';
import { useCallback, useMemo } from 'react';
import { atomCustomItem } from '@atoms/products';
import { Stack } from '@components/common';
import ProductDetailFormContent, {
  ProductDetailFormShape,
} from '@components/products/ProductDetailFormDialog/ProductDetailFormContent';
import { ProductDetailLoader } from '@components/products/loaders';
import { CLOSED_BILL_QUERY_PARAMS, OPEN_BILL_QUERY_PARAMS } from '@constants/dineIn';
import routes from '@constants/routes';
import { useCheckDineIn, useDineInRedirectByStatus } from '@hooks/dineIn';
import { useCartStore, useCommonStore } from '@hooks/storage';
import { useQueryLocationDetail } from '@queries/locations';
import { useQueryProductDetail } from '@queries/products';
import { useQuerySubBrands } from '@queries/subBrands';
import { CustomItem } from '@storage/types';
import { getAddedCustomItemOnProduct, getUpdatedCustomItemOnCartItem } from '@utils/storage/cartStore';

const ProductDetailContainer = () => {
  useDineInRedirectByStatus();

  const { query, push } = useRouter();
  const productId = query?.id as unknown as number;
  const locationId = query?.locationId as unknown as number;
  const subBrandId = query?.subBrandId as unknown as number;
  const [editedCustomItem, setEditedCustomItem] = useAtom(atomCustomItem);
  const { storageState, isFinishInitiated: isFinishLoadCommonStore, updateStorage } = useCommonStore();
  const { isClosedBill, isOpenBill, openBillToken, closedBillToken } = useCheckDineIn();

  const activeLocationId = storageState.activeLocation?.id;
  const activeSubBrandId = storageState.subBrand?.id;

  const { isFetching: isFetchingLocation } = useQueryLocationDetail(
    {
      id: locationId,
    },
    {
      enabled: !!locationId && !activeLocationId,
      onSuccess: (response) => {
        updateStorage({
          activeLocation: response,
        });
      },
    }
  );

  const { isFetching: isLoadingSubBrand } = useQuerySubBrands(
    {
      locationId,
    },
    {
      enabled: !activeSubBrandId && !!subBrandId,
      onSuccess: ({ subBrands }) => {
        const findSubBrand = subBrands.find((subBrand) => subBrand.id == subBrandId);

        updateStorage({
          subBrand: findSubBrand,
        });
      },
    }
  );

  const { data, isFetching, queryKeys } = useQueryProductDetail({
    id: productId,
  });

  const {
    updateProductCartItem,
    branchCart,
    isFinishInitiated: isFinishLoadCartStore,
  } = useCartStore(activeLocationId);

  const cartItem = data && branchCart[data?.id];

  const productDetailFormInitialValue: ProductDetailFormShape = useMemo(() => {
    let notes;
    let productOptionSets;
    if (cartItem) {
      notes = cartItem?.notes;
      if (editedCustomItem) {
        notes = editedCustomItem?.notes;
        productOptionSets = editedCustomItem?.productOptionSets;
      }
    }

    return {
      productOptionSets,
      notes,
    };
  }, [cartItem, editedCustomItem]);

  const productDescription = useMemo(() => {
    return data?.description || '';
  }, [data?.description]);

  const onUpdateCustomItem = useCallback(
    (value: ProductDetailFormShape) => {
      const newCustomItem = mapProductDetailShapeToCustomItem(value);

      const newProduct = getUpdatedCustomItemOnCartItem(editedCustomItem, cartItem, newCustomItem);
      updateProductCartItem(productId, newProduct);
      setEditedCustomItem(undefined);
      return push({
        pathname: routes.PRODUCT_LIST,
        query: {
          ...(isClosedBill && {
            [CLOSED_BILL_QUERY_PARAMS]: closedBillToken,
          }),
          ...(isOpenBill && {
            [OPEN_BILL_QUERY_PARAMS]: openBillToken,
          }),
        },
      });
    },
    [
      cartItem,
      closedBillToken,
      editedCustomItem,
      isClosedBill,
      isOpenBill,
      openBillToken,
      productId,
      push,
      setEditedCustomItem,
      updateProductCartItem,
    ]
  );

  const mapProductDetailShapeToCustomItem = (value: ProductDetailFormShape): CustomItem => {
    const productOptionSets = value?.productOptionSets?.map((optionSet) => ({
      optionSetId: optionSet?.optionSetId,
      optionSetOptionIds: optionSet?.optionSetOptionIds,
    }));

    const customItem = {
      productOptionSets,
      qty: 1,
      notes: value?.notes,
    };

    return customItem;
  };

  const onAddNewItem = useCallback(
    (value: ProductDetailFormShape) => {
      if (data) {
        const productCart = {
          ...branchCart[data.id],
          id: branchCart[data.id]?.id || productId,
          qty: branchCart[data.id]?.qty || 0,
          productCategoryName: data?.productCategoryName,
          productCategoryId: data?.productCategoryId,
          queryKeys,
        };
        const isHaveProductOptionSets = data?.productOptionSets?.length;
        if (isHaveProductOptionSets) {
          const newCustomItem = mapProductDetailShapeToCustomItem(value);
          const newProduct = getAddedCustomItemOnProduct(productCart, newCustomItem);
          updateProductCartItem(productId, newProduct);
        } else {
          updateProductCartItem(productId, {
            ...productCart,
            qty: productCart?.qty + 1,
            notes: value?.notes,
            productCategoryName: data?.productCategoryName,
            productCategoryId: data?.productCategoryId,
            queryKeys,
          });
        }

        return push({
          pathname: routes.PRODUCT_LIST,
          query: {
            ...(isClosedBill && {
              [CLOSED_BILL_QUERY_PARAMS]: closedBillToken,
            }),
            ...(isOpenBill && {
              [OPEN_BILL_QUERY_PARAMS]: openBillToken,
            }),
          },
        });
      }
    },
    [
      branchCart,
      closedBillToken,
      data,
      isClosedBill,
      isOpenBill,
      openBillToken,
      productId,
      push,
      queryKeys,
      updateProductCartItem,
    ]
  );

  const onSubmit = useCallback(
    (value: ProductDetailFormShape) => {
      if (editedCustomItem) {
        onUpdateCustomItem(value);
      } else {
        onAddNewItem(value);
      }
    },
    [editedCustomItem, onAddNewItem, onUpdateCustomItem]
  );

  if (isFetching || !isFinishLoadCommonStore || !isFinishLoadCartStore || isFetchingLocation || isLoadingSubBrand) {
    return <ProductDetailLoader />;
  }

  return (
    <Stack direction={'column'} paddingBottom={'m'} paddingLeft={'m'} paddingRight={'m'}>
      <ProductDetailFormContent
        product={data}
        productDescription={productDescription}
        onSubmit={onSubmit}
        formInitialValue={productDetailFormInitialValue}
        disabled={data?.outOfStockFlag || isFetching}
      />
    </Stack>
  );
};

export default ProductDetailContainer;
