import { useRouter } from 'next/router';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, IconButton, Image, MenuDialog, Stack } from '@components/common';
import { DineInMergedBills } from '@components/dineIn';
import { ArrowIcon, MenuIcon } from '@components/icons';
import { useQueryBrandInfo } from '@queries/commons';

interface Props {
  onPressBack?: () => void;
}

const ProductCartFormNavbar: FC<Props> = (props) => {
  const { t } = useTranslation();
  const { onPressBack } = props;
  const { data } = useQueryBrandInfo();

  const [openMenu, setOpenMenu] = useState(false);

  const router = useRouter();

  const onOpenMenu = () => {
    setOpenMenu(true);
  };

  const onCloseMenu = () => {
    setOpenMenu(false);
  };

  const handleOnPressBack = () => {
    onPressBack ? onPressBack() : router.back();
  };

  const brandLogo = data?.brand?.logoUrl;
  const brandName = data?.brand?.name;

  return (
    <Stack
      padding={'m'}
      paddingRight={'xs'}
      direction={'row'}
      justifyContent={'space-between'}
      background={'uiLightPrimary'}
    >
      <Button onClick={handleOnPressBack} variant={'text'} padding={0}>
        <ArrowIcon />
      </Button>

      <Image
        width={30}
        height={30}
        src={brandLogo}
        alt={t('general.alt_logo_image', { brandName })}
        objectFit={'contain'}
      />
      <Stack flexDirection={'row'} alignItems={'center'}>
        <DineInMergedBills />
        <IconButton onClick={onOpenMenu}>
          <MenuIcon />
        </IconButton>
      </Stack>
      <MenuDialog open={openMenu} onClose={onCloseMenu} />
    </Stack>
  );
};

export default ProductCartFormNavbar;
