import { keyBy, sortBy } from 'lodash';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, Typography } from '@components/common';
import { useDineInOrderDetail } from '@hooks/dineIn';
import { useCartStore, useCommonStore } from '@hooks/storage';
import { useQueryProductCartList, useQueryProducts } from '@queries/products';
import { CartItem } from '@storage/types';
import ProductCartFormCard from './ProductCartFormCard';
import ProductCartFormLoader from './ProductCartFormLoader';

interface Props {
  disabled?: boolean;
}

const ProductCartForm: React.FC<Props> = (props) => {
  const { disabled } = props;

  const { t } = useTranslation();
  const { isFetching, data, queryKeys } = useQueryProducts();
  const { productCartList, isFetching: isFetchingCartList } = useQueryProductCartList();

  const { dineInDetail, isFetching: isFetchingDineInDetail } = useDineInOrderDetail();

  const { storageState, isFinishInitiated: isFinishLoadCommonStore } = useCommonStore();
  const activeLocationId = storageState.activeLocation?.id;
  const {
    updateProductCartItem,
    branchCart,
    isFinishInitiated: isFinishLoadCartStore,
  } = useCartStore(activeLocationId);

  const onSetItem = (productId: number) => (item: CartItem) => {
    updateProductCartItem(productId, { ...item, queryKeys });
  };

  const mappedProductList = keyBy(productCartList, 'id');

  const cartList = useMemo(() => {
    const unsortedCardList = Object.keys(branchCart).map((productId) => branchCart[Number(productId)]);

    const sortedCardList = sortBy(unsortedCardList, 'sequence');

    return sortedCardList;
  }, [branchCart]);

  const favoriteProductIds = useMemo(() => data?.favoriteMenu?.productIds || [], [data?.favoriteMenu?.productIds]);

  if (
    isFetching ||
    !isFinishLoadCommonStore ||
    !isFinishLoadCartStore ||
    isFetchingDineInDetail ||
    isFetchingCartList
  ) {
    return <ProductCartFormLoader />;
  }

  return (
    <Stack direction={'column'} spacing={'xl'}>
      <Typography size={'xl'} variant={'bold'}>
        {dineInDetail?.tableNo
          ? t('products.cart_dine_in_page_title', { tableNo: dineInDetail.tableNo })
          : t('products.cart_page_title')}
      </Typography>
      {cartList.map((cart) => {
        if (cart.customItems) {
          return cart.customItems.map((customItem, cItemIdx) => {
            return (
              <ProductCartFormCard
                cartItem={cart}
                onChangeCartItem={onSetItem(cart.id as number)}
                disabled={disabled}
                product={mappedProductList[cart.id as number]}
                customItem={customItem}
                isFavorite={favoriteProductIds.includes(cart.id as number)}
                key={`${cart.id}-c${cItemIdx}`}
              />
            );
          });
        }
        return (
          <ProductCartFormCard
            cartItem={cart}
            onChangeCartItem={onSetItem(cart.id as number)}
            disabled={disabled}
            product={mappedProductList[cart.id as number]}
            isFavorite={favoriteProductIds.includes(cart.id as number)}
            key={cart.id}
          />
        );
      })}
    </Stack>
  );
};

export default ProductCartForm;
