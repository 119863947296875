import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Product } from '@api/products/types';
import { Button, Stack, Typography } from '@components/common';
import { useProductCartTotalPrices } from '@hooks/products';
import { useCartStore, useCommonStore } from '@hooks/storage';
import { Skeleton } from '@mui/material';
import { toLocaleStringNumber } from '@utils/number';

interface Props {
  productList: Product[];
  disabled?: boolean;
  handleSubmit: () => void;
}

const ProductCartOnlineSubmitButton = ({ productList = [], disabled = false, handleSubmit }: Props) => {
  const { t } = useTranslation();

  const { storageState, isFinishInitiated: isFinishLoadCommonStore } = useCommonStore();
  const activeLocationId = storageState.activeLocation?.id;
  const { branchCart, isFinishInitiated: isFinishLoadCartStore } = useCartStore(activeLocationId);

  /**
   * This variable is to check whether there is an out of stock product or product options
   */
  const disabledSubmitButton = useMemo(() => {
    if (disabled) {
      return true;
    }
    const productIds = Object.keys(branchCart);

    return productIds.some((productId) => {
      const matchedProduct = productList.find(({ id }) => productId === String(id));
      if (!matchedProduct || matchedProduct?.outOfStockFlag) {
        return true;
      }

      const selectedProduct = branchCart[Number(productId)];
      return selectedProduct?.customItems?.some((customItem) =>
        customItem.productOptionSets?.some((optionSet) => {
          const matchedOptionSet = matchedProduct?.productOptionSets?.find?.(
            ({ optionSetId }) => optionSetId === optionSet?.optionSetId
          );
          return optionSet?.optionSetOptionIds?.some((optionSetOptionId) =>
            matchedOptionSet?.optionSetOptions?.some(
              ({ id, outOfStockFlag }) => optionSetOptionId === id && outOfStockFlag
            )
          );
        })
      );
    });
  }, [branchCart, disabled, productList]);

  const totalPrice = useProductCartTotalPrices({ products: productList });

  const totalPriceString = totalPrice ? toLocaleStringNumber(String(totalPrice)) : '';
  const submitButtonLabel = `${t('products.cart_submit_label')} ${totalPriceString}`.trim();

  const isLoading = !isFinishLoadCommonStore || !isFinishLoadCartStore || disabled;

  return (
    <Stack>
      {isLoading ? (
        <Skeleton variant={'rectangular'} width={'100%'} height={33} />
      ) : (
        <Button disabled={disabledSubmitButton} onClick={handleSubmit} variant={'contained'} fullWidth>
          <Typography variant={'bold'}>{submitButtonLabel}</Typography>
        </Button>
      )}
    </Stack>
  );
};

export default ProductCartOnlineSubmitButton;
