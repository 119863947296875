import React from 'react';
import { Stack, Typography } from '@components/common';
import { Skeleton } from '@mui/material';

const SuggestedProductListLoader: React.FC = () => {
  return (
    <Stack direction={'column'} spacing={'xs'}>
      <Typography variant={'medium'} size={'hs'} color={'uiDarkSecondary'}>
        <Skeleton variant={'text'} width={100} />
      </Typography>
      <Stack spacing={'s'} overflow={'auto'} direction={'row'}>
        {new Array(4).fill(null).map((_, idx) => (
          <Stack key={idx} spacing={'xs'} padding={'xs'} background={'borderSubtle'} borderRadius={'default'}>
            <Skeleton variant={'rectangular'} width={75} height={75} />
            <Stack>
              <Typography size={'s'}>
                <Skeleton variant={'text'} width={'60%'} />
              </Typography>
              <Typography size={'xs'} color={'uiDarkSecondary'}>
                <Skeleton variant={'text'} width={'80%'} />
              </Typography>
            </Stack>
          </Stack>
        ))}
      </Stack>
    </Stack>
  );
};

export default SuggestedProductListLoader;
