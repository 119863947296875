import Decimal from 'decimal.js';
import { useAtom } from 'jotai';
import { useRouter } from 'next/router';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Product } from '@api/products/types';
import { atomCustomItem } from '@atoms/products';
import { Box, Button, Image, Stack, Tag, TextField, Typography } from '@components/common';
import { ChatFilledIcon, FavoriteIcon } from '@components/icons';
import { ProductPrice, ProductTotalInput } from '@components/products';
import routes from '@constants/routes';
import { useCommonStore } from '@hooks/storage';
import { InputProps } from '@mui/material';
import { useQueryBrandInfo } from '@queries/commons';
import { CartItem, CustomItem } from '@storage/types';
import { calcDecimal } from '@utils/number';
import { getItemTagList } from '@utils/product';
import { getTotalPriceFromCustomItem, getUpdatedCustomItemOnCartItem } from '@utils/storage/cartStore';

interface Props {
  product: Product;
  disabled?: boolean;
  isFavorite?: boolean;
  cartItem?: CartItem;
  customItem?: CustomItem;
  onChangeCartItem?: (item: CartItem) => void;
}

const ProductCartFormCard: React.FC<Props> = (props) => {
  const { product, cartItem, customItem, onChangeCartItem, isFavorite } = props;
  const { data } = useQueryBrandInfo();
  const [_, setEditedCustomItem] = useAtom(atomCustomItem);
  const { storageState } = useCommonStore();

  const outOfStock = useMemo(() => {
    if (!product) {
      return true;
    }
    if (product.outOfStockFlag) {
      return true;
    }

    return customItem?.productOptionSets?.some((optionSet) => {
      const matchedOptionSet = product?.productOptionSets?.find?.(
        ({ optionSetId }) => optionSetId === optionSet?.optionSetId
      );
      return optionSet?.optionSetOptionIds?.some((optionSetOptionId) =>
        matchedOptionSet?.optionSetOptions?.some(({ id, outOfStockFlag }) => optionSetOptionId === id && outOfStockFlag)
      );
    });
  }, [customItem?.productOptionSets, product]);

  const disabled = props.disabled;

  const disabledOrOutOfStock = disabled || outOfStock;

  const { t } = useTranslation();

  const notesInitialValue = useMemo(() => {
    if (customItem) {
      return customItem?.notes;
    }
    return cartItem?.notes;
  }, [cartItem?.notes, customItem]);

  const [showProductNotesInput, setShowProductNotesInput] = useState(false);
  const [notes, setNotes] = useState(() => notesInitialValue);

  const router = useRouter();

  useEffect(() => {
    setNotes(notesInitialValue);
  }, [notesInitialValue]);

  const { name, price, imageUrl, promoPrice } = product || {};

  const totalInputProduct = useMemo(() => {
    if (customItem) {
      return customItem?.qty || 0;
    }
    return cartItem?.qty || 0;
  }, [cartItem?.qty, customItem]);

  const hasNotes = useMemo(() => {
    if (customItem) {
      return customItem?.notes;
    }
    return cartItem?.notes;
  }, [cartItem?.notes, customItem]);

  const notesButtonColor = !hasNotes || outOfStock ? 'uiDarkSecondary' : 'uiPrimaryMain';
  const notesIconColor = !hasNotes || outOfStock ? 'uiDarkSecondary' : 'uiDarkPrimary';

  const openNotesInput = () => {
    setShowProductNotesInput(true);
  };

  const closeNotesInput = () => {
    setShowProductNotesInput(false);
  };

  const toggleNotesInput = () => {
    if (showProductNotesInput) {
      closeNotesInput();
    } else {
      openNotesInput();
    }
  };

  const onChangeNotes: InputProps['onChange'] = (e) => {
    setNotes(e?.target.value);
  };

  const saveCustomItemNotes = useCallback(() => {
    const newCustomItem = {
      ...customItem,
      notes,
    };
    const newProduct = getUpdatedCustomItemOnCartItem(customItem, cartItem, newCustomItem);
    onChangeCartItem?.(newProduct);
  }, [cartItem, customItem, notes, onChangeCartItem]);

  const saveBasicCartItemNotes = useCallback(() => {
    onChangeCartItem?.({
      ...cartItem,
      id: product?.id,
      notes,
      productCategoryId: product?.productCategoryId,
      productCategoryName: product?.productCategoryName,
    });
  }, [cartItem, notes, onChangeCartItem, product?.id, product?.productCategoryId, product?.productCategoryName]);

  const onOpenProductDetailPage = useCallback(() => {
    if (!product.id) {
      return
    }
    router.push({
      pathname: `${routes.PRODUCT_LIST}/${product.id}`,
      query: {
        ...router.query,
        locationId: storageState.activeLocation?.id,
        subBrandId: storageState.subBrand?.id,
      },
    });
  }, [product?.id, router, storageState.activeLocation?.id, storageState.subBrand?.id]);

  const saveNotes = useCallback(() => {
    if (customItem) {
      saveCustomItemNotes();
    } else {
      saveBasicCartItemNotes();
    }

    closeNotesInput();
  }, [customItem, saveBasicCartItemNotes, saveCustomItemNotes]);

  const handleOnChangeTotalCustomItem = useCallback(
    (value: number) => {
      const newCustomItem = {
        ...customItem,
        qty: value,
      };
      const newProduct = getUpdatedCustomItemOnCartItem(customItem, cartItem, newCustomItem);
      onChangeCartItem?.(newProduct);
    },
    [cartItem, customItem, onChangeCartItem]
  );

  const handleOnChangeTotalBasicItem = useCallback(
    (value: number) => {
      onChangeCartItem?.({
        ...cartItem,
        id: product?.id,
        qty: value,
        productCategoryId: product?.productCategoryId,
        productCategoryName: product?.productCategoryName,
      });
    },
    [cartItem, onChangeCartItem, product?.id, product?.productCategoryId, product?.productCategoryName]
  );

  const onChangeProductTotalInput = useCallback(
    (value: number) => {
      if (customItem) {
        handleOnChangeTotalCustomItem(value);
      } else {
        handleOnChangeTotalBasicItem(value);
      }
    },
    [customItem, handleOnChangeTotalBasicItem, handleOnChangeTotalCustomItem]
  );

  const onPressCard = () => {
    if (disabledOrOutOfStock) {
      return;
    }
    setEditedCustomItem(customItem);
    onOpenProductDetailPage();
  };

  const cardSellPrice = useMemo(() => {
    if (customItem) {
      return String(getTotalPriceFromCustomItem(customItem, product));
    }

    const parsedPromoPrice = calcDecimal(promoPrice);

    if (parsedPromoPrice instanceof Decimal && parsedPromoPrice.greaterThan(0)) {
      return parsedPromoPrice.toString();
    }

    return price;
  }, [customItem, product, price, promoPrice]);

  const generateItemTags = useCallback(
    (item: CustomItem) => {
      if (product && item) {
        return getItemTagList(product, item);
      }

      return [];
    },
    [product]
  );

  return (
    <Stack direction={'column'} spacing={'xs'}>
      <Stack direction={'row'} spacing={'m'} onClick={onPressCard}>
        <Image
          alt={t('general.alt_product_image', { productName: name, brandName: data?.brand?.name })}
          borderRadius={'default'}
          disabled={disabledOrOutOfStock}
          width={78}
          height={78}
          src={imageUrl}
          objectFit={'cover'}
          flexShrink={0}
        />
        <Stack direction={'column'} justifyContent={'space-between'} spacing={'xs'}>
          <Stack direction={'column'} spacing={'xs'} paddingTop={'xs'}>
            <Stack direction={'row'} alignItems={'center'} spacing={'xs'}>
              <Typography variant={'bold'}>{name}</Typography>

              {isFavorite && <FavoriteIcon width={16} height={16} />}

              {outOfStock ? (
                <Tag
                  backgroundColor={'uiLightStain'}
                  textColor={'uiDarkPrimary'}
                  text={t('products.out_of_stock_label')}
                  stackProps={{
                    borderRadius: 'rounded',
                    paddingX: 'xs',
                    minWidth: 'max-content',
                    alignSelf: 'flex-start',
                  }}
                />
              ) : (
                <Button
                  minHeight={0}
                  padding={0}
                  size={'small'}
                  onClick={toggleNotesInput}
                  disabled={disabled}
                  stopPropagation
                >
                  <Box
                    borderColor={notesButtonColor}
                    borderWidth={'thin'}
                    borderRadius={'default'}
                    width={28}
                    height={28}
                    alignItems={'center'}
                    justifyContent={'center'}
                    display={'flex'}
                  >
                    <ChatFilledIcon disabled={disabled} color={notesIconColor} />
                  </Box>
                </Button>
              )}
            </Stack>
            {customItem && (
              <Stack
                direction={'row'}
                alignItems={'center'}
                flexWrap={'wrap'}
                style={{ marginLeft: '-4px', marginRight: '-4px' }}
              >
                {generateItemTags(customItem)?.map((tagProps, index) => (
                  <Tag
                    stackProps={{
                      paddingX: 'xs',
                      margin: 'xxs',
                    }}
                    textProps={{
                      size: 'xs',
                    }}
                    key={index}
                    text={tagProps?.text}
                  />
                ))}
              </Stack>
            )}
            <ProductPrice price={cardSellPrice} />
            <Typography size={'hs'} color={'uiPrimaryMain'}>
              {notesInitialValue || ''}
            </Typography>
          </Stack>
          <ProductTotalInput
            disabledIncrement={outOfStock}
            disabled={disabled}
            onChange={onChangeProductTotalInput}
            value={totalInputProduct}
          />
        </Stack>
      </Stack>

      {showProductNotesInput && (
        <Stack direction={'column'} spacing={'xs'}>
          <TextField
            key={notesInitialValue}
            rows={3}
            multiline
            defaultValue={notes}
            onBlur={onChangeNotes}
            placeholder={t('products.product_comment_placeholder')}
          />
          <Button disabled={disabledOrOutOfStock} onClick={saveNotes} variant={'contained'} color={'primary'}>
            {t('products.action_save_label')}
          </Button>
        </Stack>
      )}
    </Stack>
  );
};

export default ProductCartFormCard;
